.chat-container{
    width: 60rem !important;
    background-color: #F6F3FF;
}
.chat-background{

}
.chat-input{
    border-top-left-radius: 22px !important;
    border-bottom-left-radius: 22px !important;
    border-right: none !important;
    height: 24px;
}  

.login-input{
    border-radius: 22px !important;
    height: 3rem !important;
}
.register-link{
    color:var(--themeColor);
    cursor: pointer;
}
.purple-border{
    border-bottom: 4px solid #936AF4 ;
}
.suggestion{
    border: 1px solid #936AF4;
    opacity: 80%;
    cursor: pointer;
}
.icon{
    width: 1.3rem ;
    height: 1.3rem ;
    cursor: pointer ;
}
.chat-button{
    border-top-right-radius: 22px !important;
    border-bottom-right-radius: 22px !important;
    border: 1px solid #DFDFDF !important;
    background-color: white !important;
    border-left: none !important;
    
}
.typing{
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  background-color: #936AF4;
  opacity: 70%;
  animation: typing 1000ms ease-in-out infinite;
  animation-delay: 3600ms;
}

@keyframes typing{
    0%{
        transform: scale(1);
    }
    33%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.3);
    }
    100%{
        transform: scale(1);
    }
}
.typing:nth-child(1){
    animation-delay: 0ms;
}
.suggestions{
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
}
.suggestions::-webkit-scrollbar {
    width: 0;
}
.typing:nth-child(2){
    animation-delay: 333ms;
}
  
.typing:nth-child(3){
    animation-delay: 666ms;
}
.chat-bubble{
    background-color: white;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 2px 25px #F0EDF9;
    opacity: 0;
    padding: 1rem 3rem;
    align-self: flex-start;
}
.bot-message{
    border-radius: 0px 22px 22px 22px !important;
    animation: botmessage 0.3s ease-out 0s forwards;
    align-self: flex-start;
}
.user-message{
    border-radius: 22px 0px 22px 22px !important;
    animation: usermessage 0.3s ease-out 0s forwards;
    align-self: flex-end;
}

.option-buttons{
    background: var(--themeColor) !important;
    box-shadow: 0px 2px 25px #F0EDF9 !important;
    border-radius: 22px !important;
    border: 1px solid var(--themeColor) !important;
    padding: 0.7rem 3rem !important;

  }
.chat-scroll{
    overflow-y: scroll;
    scrollbar-width: thin;
}
.chat-scroll::-webkit-scrollbar {
    width: 1rem;
}
.samurai-logo{
    width: 6rem;
}
.logo-graphic{
    width: 5rem;
}
.accordion-header>.accordion-button{
    font-weight: bold;
}
.accordion-button:not(.collapsed) {
    background-color: #F6F3FF !important;
}
.accordion{
    border-radius: 22px !important;
}
.message-icon{
    width: 2rem;
    height: 2rem;
    object-fit: cover;
}
.share-container{
    max-height: 30rem;
}
@keyframes botmessage {
    0% {
        margin-left: -2rem;
        opacity: 0;
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
  }

  @keyframes usermessage {
    0% {
        margin-right: -2rem;
        opacity: 0;
    }
    80% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
  }

@media screen and (max-width:500px){
    .bot-messages{
        padding: 0.5rem 2rem;
    }
}