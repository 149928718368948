.summary-body{
    width: 100% !important;
}

@media screen and (min-width:768px){
    /* .summary-cards{
        min-height: 80px !important;
        min-width: 430px !important;
        border-bottom: 5px solid #936AF4 !important;
        margin: 20px 20px 0px 20px !important;
        padding-bottom: 20px !important;
    } */

    /* .summary-cards-big{
        min-height: 380px !important;
        min-width: 470px !important;
        padding: 20px !important;
    } */

   
}
.top-filters-cards.dropdown-toggle::after{
    content: url('../assets/expand_more.svg');
    border: none;
    height: auto;
    vertical-align: middle;
}
    /* .summary-cards{
    min-height: 80px !important;
   
    border-bottom: 3px solid #936AF4 !important;
    margin: 20px 20px 0px 20px !important;
    padding-bottom: 20px !important;
} */

/* .summary-cards-full-width{
    margin-right: 120px !important;
 } */

/* .summary-cards-big{
    min-height: 380px !important;
    padding: 20px !important;
} */

.summary-cards-icon-bg{
    background: #F6F3FF;
    border-radius: 15px;
    /* height: 3rem;
    width:3rem; */
}

.summary-cards-icon{
 color: #763FF9 !important;
}

.stat-cards{
    box-shadow: 0px 2px 25px 0px #F0EDF9;
    border-bottom: 4px solid #936AF4 ;
    border-radius: 1.375rem;
    background-color: #fff;
    height: 4.688rem;
    width: 23.438rem;
    padding: 1.125rem ;
}
.stat-row>.col{
    display: flex;
    align-items: center;
    justify-content: center;
}
.summary-helper-text-bg{
    background: #F6F3FF;
    border-radius: 2.42rem;
}


@media screen and (max-width:768px){
    .stat-cards{
        flex: 1;
        /* margin: 0 1rem; */
    }
}