.bot-container{
    width: 80rem !important;
}
.embed-container{
    min-height: 25rem;
    min-width: 30rem;
}
.bot-embed{
    min-height: 25rem;
}
.heybot-chat-bubble{
    background-color: white;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 2px 25px #F0EDF9;
    opacity: 0;
    padding: 0.5rem 1rem;
}
.heybot-chat-bubble>pre{
    white-space: break-spaces;
    margin: 0;
}
.link-card{
    width: 100%;
}
.heybot-small-button{
    background: var(--themeColor) !important;
    box-shadow: 0px 2px 25px #F0EDF9 !important;
    border-radius: 22px !important;
    border: 1px solid var(--themeColor) !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.75rem !important;
}
.heybot-logo{
    width: 4rem;
}
.heybot-toggle{
    background-color: transparent !important;
    border: none !important;
  }
.heybot-toggle::after {
    content: none !important;
  }

.heybot-powered-by{
    background-color: var(--themeColor);
    border-radius:  12px 12px  0 0;
    top: auto;
    right: 0;
    bottom: 0;
  }
@media screen and (max-width:768px){
    .bot-container{
        width: 100% !important;
    }
    .embed-container{
        min-width: 100%;
    }
}