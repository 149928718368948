.agi-bg{
    background-color: #2b2d2f !important;
    color: white !important;
}
.agi-send{
    background-color: white !important;
    border-left: none !important;
}
.agi-side-bar{
    background-color: rgb(40, 40, 40) !important;
    color: whitesmoke !important;
    
}
.agi-buttons{
    background: var(--themeColor) !important;
    border-radius: 12px !important;
    border: 1px solid var(--themeColor) !important;
    padding: 0.7rem 3rem !important;
    max-height: 3rem;
}
.agi-buttons-small{
    background: var(--themeColor) !important;
    border-radius: 12px !important;
    border: 1px solid var(--themeColor) !important;
    padding: 0.4rem 1rem !important;
    max-height: 3rem;
  }
.agi-block{
    background-color: rgb(40, 40, 40) !important;
    border-radius: 12px;
    color: white !important;
}
.agi-accordion>.accordion-header>.accordion-button::after {
    filter: invert(50%);
}
.thoughts>p{
    margin: 0;
}
.output-modal>.modal-dialog{
    min-width: 60% !important;
  
  }
.agi-block-side,.agi-accordion,.agi-accordion>.accordion-header>.accordion-button,.agi-side-btn{
    background-color: #2b2d2f !important;
    border-radius: 12px !important;
    color: white !important;
    border: none !important;
}
.task-scroll{
    max-height: 22rem;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.task-scroll::-webkit-scrollbar {
    width: 1rem;
}
.white-img{
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}

/* babyagi */

.babyagi-bg{
    background-color: rgb(20, 23, 24) !important;
    height: 100vh;
}
.babyagi-main{
    background: white;
    color: black;
    border-radius: 22px;
}
.babyagi-side-btn{
    background-color: #2b2d2f !important;
    padding: 0.4rem 2rem !important;
    border-radius: 12px !important;
    color: white !important;
    border: none !important;
}

.ball,.stationary-ball {
    width: 5px;
    height: 10px;
    border-radius: 20px;
    background: #ffffff;
  }
  
.ball:nth-child(1) {
-webkit-animation: right 1s infinite ease-in-out;
-moz-animation: right 1s infinite ease-in-out;
animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
-webkit-animation: left 1.01s infinite ease-in-out;
-moz-animation: left 1.01s infinite ease-in-out;
animation: left 1.01s infinite ease-in-out;
}

.ball:nth-child(3) {
-webkit-animation: right 1.02s infinite ease-in-out;
-moz-animation: right 1.02s infinite ease-in-out;
animation: right 1.02s infinite ease-in-out;
}

.ball:nth-child(4) {
-webkit-animation: left 1.03s infinite ease-in-out;
-moz-animation: left 1.03s infinite ease-in-out;
animation: left 1.03s infinite ease-in-out;
}

.ball:nth-child(5) {
-webkit-animation: right 1.04s infinite ease-in-out;
-moz-animation: right 1.04s infinite ease-in-out;
animation: right 1.04s infinite ease-in-out;
}



@-webkit-keyframes right {
0% {
    -webkit-transform: translateY(-5px);
}
50% {
    -webkit-transform: translateY(5px);
}
100% {
    -webkit-transform: translateY(-5px);
}
}

@-webkit-keyframes left {
0% {
    -webkit-transform: translateY(5px);
}
50% {
    -webkit-transform: translateY(-5px);
}
100% {
    -webkit-transform: translateY(5px);
}
}

@-moz-keyframes right {
0% {
    -moz-transform: translateY(-5px);
}
50% {
    -moz-transform: translateY(5px);
}
100% {
    -moz-transform: translateY(-5px);
}
}

@-moz-keyframes left {
0% {
    -moz-transform: translateY(5px);
}
50% {
    -moz-transform: translateY(-5px);
}
100% {
    -moz-transform: translateY(5px);
}
}

@keyframes right {
0% {
    transform: translateY(-5px);
}
50% {
    transform: translateY(5px);
}
100% {
    transform: translateY(-5px);
}
}

@keyframes left {
0% {
    transform: translateY(5px);
}
50% {
    transform: translateY(-5px);
}
100% {
    transform: translateY(5px);
}
}

.side-task-scroll{
    max-height: 25rem;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.side-task-scroll::-webkit-scrollbar {
    width: 1rem;
}
.result-scroll{
    max-height: 14rem;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.result-scroll::-webkit-scrollbar {
    width: 1rem;
}
.start-task{
    height: 90vh !important;
}

.agi-side-bar.offcanvas{
    width: 80% !important;
}

@media screen and (max-height:700px){
    .babyagi-bg{
        height: 100%;
    }
    .start-task{
        height: 100vh !important;
    }

}

@media screen and (min-height:800px){
    .side-task-scroll{
        max-height: 35rem;
    }
    .result-scroll{
        max-height: 25rem;
    }
    
}

@media screen and (min-height:1000px){
    .side-task-scroll{
        max-height: 50rem;
    }
    .result-scroll{
        max-height: 40rem;
    }
}