.csv-agent-bg{
    background-color: #EEE5E9;
}

.csv-agent-container{
    background-color: #2B303A;
}

.helper-input{
    background-color: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0% !important;
    color: #EEE5E9 !important;
}
.csv-input{
    border-bottom: 2px solid #736CED !important;
}
.pdf-input{
    border-bottom: 2px solid #F40F02 !important;
}
.yt-input{
    border-bottom: 2px solid #FF0000 !important;
}
.csv-agent-key{
    width: 50% !important;
}

.csv-agent-video {
    width: 40%;
    min-height: 15rem;
}
.helper-msg{
    animation: agentMsg 0.3s ease-out 0s forwards;

}
.csv-msg{
    background: #736CED;
}
.pdf-msg{
    background: #F40F02;
}
.yt-msg{
    background: #FF0000;
}
.csv-user-msg{
    background: #EEE5E9;
    color: #2B303A;
    animation: userMsg 0.3s ease-out 0s forwards;

}
.csv-agent-scroll {
    overflow-y: scroll;
    scrollbar-width: none;
}
.csv-agent-scroll::-webkit-scrollbar {
    width: 0;
}

@keyframes agentMsg {
    0% {
        margin-left: -5rem;
        opacity: 0;
    }
    100% {
        margin-left: 0;
    }
  }
  @keyframes userMsg {
    0% {
        margin-left: 5rem;
    }
    100% {
        margin-left: 0;
    }
  }

.csv-agent-loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(20, end) 2000ms infinite;
    animation: ellipsis steps(20, end) 2000ms infinite;
    content: ".......";
    /* ascii code for the ellipsis character */
    width: 2px;
}

@keyframes ellipsis {
to {
    width: 100px;
}
}
@media screen and (max-width:768px) {
    .csv-agent-key{
        width: 100% !important;
    }
    .csv-agent-video {
        width: 100%;
        min-height: 14rem;
    }
}