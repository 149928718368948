.ritebot-bg{
    background-color: var(--themeColor);
    overflow-y: hidden;
}
.ritebot-msg{
    background-color: var(--themeColor);
    box-shadow: 0px 1px  12px rgba(0,0,0,0.55);
    color: white;
    max-width: 70%;
    animation: botmessage 0.2s ease-out 0s forwards;


}
.ritebot-button{
    background-color: #6fc7d5 !important;
    color: black !important;
    font-weight: 500 !important;
    border-radius: 12px !important;
    padding: 0.5rem 1.5rem !important;
    border: none !important;
}
.ritebot-input>.chat-input,.ritebot-input>.chat-button{
    border-color: var(--themeColor) !important;
    border-width: 2px !important;
}
@keyframes botmessage {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    50% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
  }
.chat-bg{
    background-color: white;
    border-top-right-radius: 44px;
    border-top-left-radius: 44px;
    flex: 1;
    box-shadow: 0px -4px 40px 10px rgba(0,0,0,0.55);
}

.ritebot-logo{
    width: 4rem;
}

.ritebot-scroll{
    /* max-height: 620px ; */

    overflow-y: scroll;
    scrollbar-width: none;
}
.ritebot-scroll::-webkit-scrollbar {
width: 0;
}
.ritebot-response{
    background-color: transparent !important;
    font-family: var(--fontFamily) !important;
    white-space: break-spaces;
    font-size: 1rem !important;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 !important;
}

.ritebot-chat-container{
    height: calc(100%);
}
@media screen and (max-width:768px){
    .chat-bg{
        border-top-right-radius: 33px;
        border-top-left-radius: 33px;
    }
    .ritebot-button{
       width: 100%;
    }
    /* .ritebot-scroll{
        max-height: 680px ;

    } */
}