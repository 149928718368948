.bg-myBg{
    background-color: #FFFFFF !important;
}
.bg-myPrimary{
    background-color: #7899C5 !important;
}
.bg-mySecondary{
    background-color: #A7C7E7 !important;
}
.bg-black{
    background-color: #000000 !important;
}
.btn-primary {
    background-color: #FFFFFF !important;
}
.bg-darkGray{
    background-color: rgba(15,23,42,0.9);
}
.opengpt-logo{
    width: 3.125rem;
    height: 3.125rem;
}
.opengpt-small-logo{
    width: 1.5rem;
    height: 1.5rem;
}
.opengpt-title{
    font-size: 1.875rem;
}
.opengpt-subtitle{
    font-size: 1.5rem;
}
.opengpt-container{
    max-width: 48rem;
}
.opengpt-small{
    font-size: 0.9rem;
}
.opengpt-button:hover{
    background-color: rgba(96,165,250,0.4) !important;
}
.bounce {
    height: 8px;
    width: 8px;
    animation: dot-pulse 0.9s ease both infinite;
}
@keyframes dot-pulse {
    0% { transform: scale(0); }
    50% { transform: scale(1); }
    100% { transform: scale(0); }
}
  
.bounce1 { animation-delay: 0; }
.bounce2 { animation-delay: .3s; }
.bounce3 { animation-delay: .6s; }

.gptauth-logo{
    width: 5rem;
    height: 5rem;
}
.gptauth-title{
    font-size: 4rem;
}
.bg-lightGray{
    background-color: rgba(226,232,240,0.2 );
}
.gptauth-btn{
    background-color: rgb(51 65 85 / 0.7) !important;
}
.gptauth-small-logo{
    width: 1rem;
    height: 1rem;
}
.gptauth-input{
    min-width: 25rem !important;
}
.spec-container{
    max-height: 10rem;
    overflow-y: scroll;
}
.gptauth-tab.active, .show>.gptauth-tab{
    background-color: rgb(51 65 85 / 0.7) !important;
    border-radius: 0 !important;
}
.gptauth-tab:not(.active){
    color: black !important;

}
@media screen and (max-width:500px){
    .gptauth-input{
        min-width: 15rem !important;
    }
}
