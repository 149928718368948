.mygpt-buttons {
  background: #f4c644 !important;
  border-radius: 15px !important;
  border: 1px solid #f4c644 important;
  border-color: #f4c644 !important ;
  height: 40px !important;
  color: black !important;
  transition: background-color 0.5s ease-in-out !important; /* add transition property */

  text-align: left !important;
}

.mygpt-enter-key-btn {
  background: #f4c644 !important;
  border-radius: 15px !important;
  border: 1px solid #f4c644 important;
  border-color: #f4c644 !important ;
  height: 40px !important;
  color: black !important;
  transition: background-color 0.5s ease-in-out !important; /* add transition property */

  text-align: center !important;
}

.mygpt-buttons:hover {
  background: #d6a62e !important;
}
.mygpt-enter-key-btn :hover {
  background: #d6a62e !important;
}
.mygpt-usermenu {
  border-radius: 15px !important;
  background-color: #232627 !important;
  --bs-accordion-bg: transparent !important;
  align-items: center !important;
  justify-content: center !important;
  color: white !important;
}
.mygpt-usermenu > .accordion-button {
  background-color: #232627 !important;
  height: 40px !important;
  border-radius: 15px !important;
  font-weight: normal !important;
  --bs-accordion-btn-color: white !important;
  --bs-accordion-btn-focus-box-shadow : none !important
}

.mygpt-usermenu > .accordion-button:hover {
  background: #34383a !important;
}

.mygpt-usermenu > .accordion-button:not(.collapsed) {
  color: #ffffff !important;
  
}


.mygpt-usermenu > .accordion-button.collapsed::after{
  
  color: #ffffff !important;
  margin-left: auto;
  filter: brightness(0) invert(1) !important;
}

.mygpt-usermenu > .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg) !important;
  background-size: 1rem;
  color: #ffffff !important;
  filter: brightness(0) invert(1) !important;
}

.mygpt-userimage {
  height: 30px !important;
  width: 30px !important;
  border-radius: 20px;
}



.mygpt-search-bg {
  border-radius: 15px !important;
  border: 2px solid #464646 !important;
}

.profile-img {
  width: 2rem;
  height: 2rem;
}
.chat-border {
  box-shadow: 7px 7px 14px rgba(149, 108, 244, 0.2),
    -7px -7px 14px rgba(167, 158, 189, 0.2);
  border-radius: 22px;
}
.formatted-ans {
  white-space: break-spaces;
  font-family: var(--fontFamily) !important;
  margin: 0;
  font-size: 1rem !important;
}
.mygpt-logo {
  width: 4rem;
}
.gpt-msg {
  background-color: #fffbf3;
  color: #000000;
  border-radius: 15px;
}

.user-msg {
  border: 1px solid #f1f1f1 !important ;
  color: #000000;
  border-radius: 15px;
}

.gpt-msg-dark {
  background-color: #303030;
  color: #ffffff;
  border-radius: 15px;
}

.user-msg-dark {
  border: 1px solid #313131 !important ;
  color: #ffffff;
  border-radius: 15px;
}

.model-popup > .dropdown-item{
  --bs-dropdown-link-hover-bg: #f4c644  !important;
}

.loading > .spinner-grow {
  background-color: rgba(149, 108, 244, 0.6);
  height: 0.7rem;
  width: 0.7rem;
}
.loading > .spinner-grow:nth-child(2) {
  animation-delay: 50ms;
}
.loading > .spinner-grow:nth-child(3) {
  animation-delay: 100ms;
}
.main-chat {
  height: 95vh;
}
.small-icon {
  width: 1rem;
  height: 1rem;
}

.product-names,.product-names > a{
  cursor: pointer;
  color: white;
  text-decoration:none;
  font-size: 12px !important; 
}

.product-names > a:hover{
  color: #f4c644;
}

@media screen and (max-width: 1200px) {
  .main-chat {
    height: 85vh;
  }
  .mygpt-logo {
    width: 2rem;
  }
}
.heybot-button {
  background-color: var(--themeColor);
  color: white;
  border-radius: 22px;
  text-decoration: none;
}
.heybot-button:hover {
  color: white;
}
.discord-invite:hover {
  color: white;
}
.side-bar {
  background-color: #141718 !important;
  color: rgb(255, 255, 255) !important;
  width: 18rem !important;
}
.side-bar-col {
  width: 18rem !important;
  max-width: 18rem !important;
}

.main-chat-col {
  width: 100% !important;
  max-width: 100% !important;
}

.previous-sess {
  cursor: pointer;
  color: #ffffff;
  transition: background 0.3s ease-in-out; /* add transition property */
  padding: 5px 5px;
  border-radius: 15px !important;
}

.previous-sess:hover {
  background: #232627 !important;
}

.prompt-modal > .modal-dialog > .modal-content > .modal-header{
  border:none !important;
  border-top-left-radius:15px !important;
  border-top-right-radius:15px !important;
}

.prompt-modal > .modal-dialog > .modal-content > .modal-header> .btn-close{
  background-color:#ffffff !important;
}

.prompt-modal > .modal-dialog > .modal-content {
  border-radius: 15px !important;
  border:none !important;
}
.prompt-modal > .modal-dialog {
  min-width: 60% !important;
  border:none !important;
}
.plugins-modal > .modal-dialog {
  min-width: 60% !important;

}

.key-modal{
  border-radius: 12px !important;
}
.key-video {
  width: 100%;
  min-height: 14rem;
  border-radius: 12px;
}

.mygpt-google-btn{
  background: #ffffff !important;
  color: #000000 !important;
  border: none !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.12) !important;

}
.prompt-tabs > .nav-item > button {
  color: #000000 !important;
  background-color: #ffffff;
  border-radius: 15px;
  margin: 0.5rem;
  padding: 2px 10px;
  font-size: 14px;
  border: 1px solid #999999;
}

.prompt-tabs > .nav-item > button.active {
  background-color: #ececec !important;
  border: 1px solid #999999;
}

.prompt-tabs-dark > .nav-item > button {
  color: #ffffff !important;
  background-color: #1d1d1d;
  border-radius: 15px;
  margin: 0.5rem;
  padding: 2px 10px;
  font-size: 14px;
  border: none !important;
}

.prompt-tabs-dark > .nav-item > button.active {
  background-color: #ececec !important;
  border: none;
  color: #000000 !important;
}


.form-control:focus {
  box-shadow: none !important;
}
.session-scroll {
  max-height: 620px !important;
  overflow-y: scroll;
  scrollbar-width: none;
}
.session-scroll::-webkit-scrollbar {
  width: 0;
}
.prompt-list {
  /* flex: 1; */
  height: 300px;
  overflow-y: auto;
  cursor: pointer !important;
}

.prompt-list-dark {
  /* flex: 1; */
  height: 300px;
  overflow-y: auto;
  cursor: pointer !important;
}


.prompt-list > .active{
    background-color: #ececec!important;
    color: #000000 !important;
    border: 1px solid #99999900 !important;
}

.prompt-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.prompt-list li {
  cursor: pointer !important;
  padding: 10px;
}

.prompt-list li > .active {
    background-color: #000000 !important;
  }

.prompt-list ul:hover {
  background-color: #eee !important;
}

-----------------------


.prompt-list-dark > .active{
  background-color: #ececec !important;
  color: #000000 !important;
  border: 1px solid #99999900 !important;
}

.prompt-list-dark ul {
list-style: none;
margin: 0;
padding: 0;
}

.prompt-list-dark li {
cursor: pointer !important;
padding: 10px;
}

.prompt-list-dark li > .active {
  background-color: #000000 !important;
}

.prompt-list-dark ul:hover {
background-color: #eee !important;
}
.prompt-textarea {
  height: 250px;
  border-radius: 15px !important;
  border: 1px solid #ced4da !important;
}

.prompt-textarea-dark {
  height: 250px;
  border-radius: 15px !important;
  background-color: #1d1d1d !important;
  border: none !important;
  color: white !important;
}

.prompt-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.prompt-buttons button {
  margin-left: 10px;
  padding: 8px 10px;
  border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 15px !important;
  transition: background-color 0.3s ease-in-out,color 0.3s ease-in-out;
}

.prompt-buttons button:hover {
  background-color: #ececec;
  color: #000000;
  border: 1px solid black;
}

.prompt-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.prompt-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.prompt-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.prompt-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .modal-90w{
min-width: 80% !important;
max-width:80% !important;
} */
.plugin-container {
  width: 50rem;
  /* height: 50rem; */
  border-radius: 44px;
  background-color: white;
  box-shadow: 10px 10px 20px rgba(149, 108, 244, 0.6),
    -10px -10px 20px rgba(167, 158, 189, 0.6);
}
.plugin-bg {
  background-color: rgb(149, 108, 244, 0.6) !important;
}
.chat-share-bg {
  background-color: #141718 !important;
}
.chat-share-container {
  width: 50rem;
  /* height: 50rem; */
  border-radius: 44px;
  background-color: white;
 
}
.plugin-icon {
  max-width: 2.5rem;
  max-height: 2.5rem;
}
.plugin-btn {
 border: 1px solid #ced4da;
  border-radius: 15px;
}
.steps-bg {
  background-color: #fafafa;
  border-radius: 12px;
  width: max-content;
}

.mygpt-icon {
  height: 20px !important;
  width: 20px !important;
}

.mygpt-icon-hover:hover {
  color: #ff5555 !important;
}

.icon-small {
  width: 1rem;
  height: 1rem;
}
.pill-scroll {
  overflow-x: auto;
}
.plugin-logo {
  width: 2rem;
  height: 2rem;
}
.citations-bg {
  background-color: #fafafa;
  border-radius: 12px;
  width: 10rem;
}
.share-btn {
  font-size: 12px !important;
  border: 1px solid #999999 !important;
  background-color: transparent !important;
  padding: 0.2rem 0.5rem !important;
 color:black !important;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.share-btn:hover {
  background-color: #ececec !important;
}

.share-btn-dark {
  font-size: 12px !important;
  border: 1px solid #999999 !important;
  background-color: transparent !important;
  padding: 0.2rem 0.5rem !important;
 color: white  !important;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.share-btn-dark:hover {
  background-color: #3f3f3f !important;
}

.function-btn {
  font-size: 14px !important;
  padding: 0.2rem 0.5rem !important;
  border-radius: 15px;
  cursor: pointer;
  transition: filter 0.3s ease-in-out;
}

.mygpt-chat-input-container {
  border: 1px solid #999999 !important;
  border-radius: 15px !important;
  width: 100% !important;
  align-items: center;
  color: #000000 !important;
}

.mygpt-chat-input-container-dark {
  border: 1px solid #999999 !important;
  border-radius: 15px !important;
  width: 100% !important;
  align-items: center;
  color: whitesmoke  !important;
}

.mygpt-chat-input {
  border: none !important;
  background-color: transparent !important;
  border-radius: 15px !important;
  padding: 10px !important;
  resize: none !important;
  color: black !important;
}

.mygpt-chat-input-dark {
  border: none !important;
  background-color: transparent !important;
  border-radius: 15px !important;
  padding: 10px !important;
  resize: none !important;
  color: whitesmoke !important;
}


.function-btn:hover {
  filter: brightness(85%) !important;
}

.function-btn > .dropdown > button {
  font-size: 14px !important;
  padding: 0rem 0.1rem !important;
  align-content: center;
}

.blinking-cursor {
  -webkit-animation: 0.4s blink step-end infinite;
  -moz-animation: 0.4s blink step-end infinite;
  animation: 0.4s blink step-end infinite;
}

.blinking-cursor-mygpt {
  background: #8d8d8d !important;
  -webkit-animation: 0.4s blink step-end infinite;
  -moz-animation: 0.4s blink step-end infinite;
  animation: 0.4s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@media screen and (max-width: 900px) {
  .prompt-list {
    /* flex: 1; */
    height: 150px;
    overflow-y: auto;
    cursor: pointer !important;
    
  }
  .prompt-textarea {
    min-height: 150px;
  }
}
@media screen and (max-width: 550px) {
  .plugin-container {
    margin-top: 25rem;
  }
}
pre {
  background-color: #eee;
  color: black !important;
  border-radius: 5px;
  margin: 0.5em 0px;
  padding: 1em;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

body > .skiptranslate {
  display: none;
}
